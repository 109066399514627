export const skills = [
  {
    name: "Général",
    content: [
      { name: "problemSolver", content: [] },
      { name: "independant", content: [] },
      { name: "creativity", content: [] },
      { name: "perfectionist", content: [] },
      { name: "techEnthusiast", content: [] }
    ]
  },
  {
    name: "Frontend",
    content: [
      { name: "languages", content: ["HTML", "CSS / SCSS / Flexbox", "Javascript"] },
      {
        name: "tools",
        content: ["npm / Yarn", "Webpack / Babel", "ESLint / Prettier"]
      },
      {
        name: "librairies",
        content: [
          "VueJS / Vuex / Vuetify / Routing",
          "React / Redux / Material UI / Routing",
          "SSR : NuxtJS / Gatsby",
          "jQuery / Bootstrap",
          "Leaflet / OpenLayers"
        ]
      },
      {
        name: "testing",
        content: ["Cypress"]
      }
    ]
  },
  {
    name: "Backend",
    content: [
      { name: "languages", content: ["Java", "Kotlin", "Python"] },
      {
        name: "tools",
        content: ["Maven", "Gradle", "pip"]
      },
      {
        name: "librairies",
        content: ["Spring Boot / Dropwizard", "Hibernate", "Django / Flask"]
      },
      {
        name: "testing",
        content: ["JUnit", "Selenium"]
      }
    ]
  },
  {
    name: "DevOps",
    content: [
      { name: "OS", content: ["Windows", "Ubuntu / Debian", "Raspbian"] },
      {
        name: "tools",
        content: ["Git / GitHub", "Docker", "VMWare", "Bash / Powershell"]
      },
      {
        name: "databases",
        content: ["H2 / SQLite", "PostgreSQL / MariaDB", "MongoDB"]
      },
      {
        name: "misc",
        content: ["Nginx / Traefik", "Redis", "HTTPS / SSL"]
      }
    ]
  },
  {
    name: "WIP",
    content: [
      { name: "languages", content: ["NodeJS", "Web assembly", "Laravel"] },
      { name: "tools", content: ["Amazon AWS"] },
      {
        name: "databases",
        content: ["GraphQL"]
      },
      { name: "misc", content: ["3D"] }
    ]
  }
]

export const experiences = [
  {
    date: "2021 - Present",
    title: { en: "Fullstack web developer", fr: "Développeur web fullstack" },
    company: { name: "Stack Labs", link: "https://stack-labs.com/" },
    description: {
      en: ``,
      fr: ``
    },
    keywords: [""]
  },
  {
    date: "2018 - 2021",
    title: { en: "Fullstack web developer", fr: "Développeur web fullstack" },
    company: { name: "ALYCE", link: "https://www.alyce.fr" },
    description: {
      en: `Creation of a cartographic web application : MyGIS.<br>
          Alyce's digital platform for analyzing mobility data.<br>
          Various development to external customers : Total, Keolis Bordeaux, Keolis Lyon, Paris city hall, etc.`,
      fr: `Création d'une application de cartographie : MyGIS.
          Plateforme numérique centralisée d'Alyce permettant le stockage et l'analyse des données de mobilité de ses clients.<br>
          Développement pour divers clients : Total, Keolis Bordeaux, Keolis Lyon, Mairie de Paris, etc.`
    },
    keywords: ["VueJS", "Leaflet", "Java", "PostgreSQL", "Docker", "Geoserver"]
  },
  {
    date: "2017 - 2018",
    title: {
      en: "Web developer in apprenticeship",
      fr: "Développeur web en alternance"
    },
    company: { name: "MobiGIS", link: "https://www.mobigis.eu/" },
    description: {
      en: `Developing a SAAS web cartography application : MobiAnalyst-Online. 
          Platform attached to ArcGIS that allow spatial data analysis<br>
          Maintenance of a mobile application : Moveazy`,
      fr: `Développement d'une application de cartographie : MobiAnalyst-Online.
          Plateforme d'analyse et d'édition de données spatiales rattaché au service ArcGIS Online<br>
          Maintenance et évolution de Moveazy, application mobile permettant la génération d'itinéraires`
    },
    keywords: ["DojoJS", "ESRI WebAppBuilder", "Java", "PostgreSQL", "MongoDB"]
  },
  {
    date: "march - july 2015",
    title: { en: "Trainee web developer", fr: "Développeur web stagiaire" },
    company: {
      name: "AIP-Primeca",
      link: "https://www.aip-primeca-occitanie.fr/"
    },
    description: {
      en: ``,
      fr: `Conception d’un site web responsive avec Bootstrap pour présenter les projets étudiants<br>
          Maquettage sous Adode Photoshop/Illustrator / Intégration sous le CMS Joomla!`
    }
  },
  {
    date: "march - july 2014",
    title: {
      en: "Trainee network technician",
      fr: "Technicien réseau stagiaire"
    },
    company: { name: "ORAMIP", link: "https://www.atmo-occitanie.org/" },
    description: {
      en: ``,
      fr: `Maquettage d’un système et mise en production d'un système de transmission de données sans-fil entre les stations de mesures et le serveur central<br>
          Configuration routeurs 3G, upgrade hardware des outils de mesure, déploiement sur sites, tests et validations`
    }
  }
]

export const studies = [
  {
    date: "2017 - 2018",
    title: {
      en: "BSC Degree - System programmer analyst",
      fr: "Licence Professionnelle - Analyste programmeur système"
    },
    university: {
      en: "Blagnac university of technology, FR",
      fr: "IUT de Blagnac, FR"
    }
  },
  {
    date: "2014 - 2015",
    title: {
      en: "BSC Degree - Design and integration of services and multimedia products",
      fr: "Licence Professionnelle - Conception et intégration de services et de produits multimédias"
    },
    university: {
      en: "Castres university of technology, FR",
      fr: "IUT de Castres, FR"
    }
  },
  {
    date: "2012 - 2014",
    title: {
      en: "Diploma of Higher Education - Networks & Telecommunications",
      fr: "DUT Réseaux & télécommunications"
    },
    university: {
      en: "Blagnac university of technology, FR",
      fr: "IUT de Blagnac, FR"
    }
  },
  {
    date: "2012",
    title: {
      en: "High school diploma in sciences",
      fr: "Baccalauréat scientifique"
    }
  }
]
