import "./top-section.scss"

import { Col } from "react-simple-flex-grid"
import React from "react"

function TopSection(props) {
  const { title } = props

  return (
    <Col span={12} className="TopSection">
      <h1 className="Text__H1 Text__White Text__Bold TopSection__Title">{title}</h1>
    </Col>
  )
}

export default TopSection
