import "./footer.scss"

import React from "react"

function Footer() {
  return (
    <footer className="Footer">
      <p className="Text__White Text__H3 Footer__Contact">hello[at]joffreybourdieux.com</p>
      <p className="Text__H4 Footer__Contact">
        <a
          href="https://www.linkedin.com/in/joffrey-bourdieux/"
          target="_blank"
          rel="noreferrer"
          className="Text__Lighten Text__Link"
        >
          Linkedin
        </a>{" "}
        /{" "}
        <a
          href="https://github.com/JoffreyBourdieux"
          target="_blank"
          rel="noreferrer"
          className="Text__Lighten Text__Link"
        >
          GitHub
        </a>
      </p>
    </footer>
  )
}

export default Footer
