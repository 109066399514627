import "./about.scss"

import { Col, Row } from "react-simple-flex-grid"
import { experiences, skills, studies } from "../content/about"

import Footer from "../components/footer"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import TopSection from "../components/top-section"
import { useIntl } from "gatsby-plugin-intl"

function About() {
  const intl = useIntl()
  const locale = intl.locale
  const keywords = [`about`].concat(experiences.map((x) => x.company.name))

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "about.title" })} keywords={keywords} />
      <div className="About">
        {/* TopSection */}
        <Row className="About__Top Text__Light TopSection__Separator">
          <TopSection title={intl.formatMessage({ id: "about.title" })} />
          <Col xs={10} offset={1} style={{ textAlign: "left" }}>
            <p className="Text__H3 Text__Lighten">
              {intl.formatMessage({ id: "jobfull" })}, {intl.formatMessage({ id: "currentlyAt" }).toLowerCase()}{" "}
              <a
                className="Text__Accent Text__Bold Text__Underline Company__Link"
                href="https://www.stack-labs.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                STACK LABS
              </a>
            </p>
            <p className="Text__H3 Text__Lighten">{intl.formatMessage({ id: "about.desc1" })}</p>
            <p className="Text__H3 Text__Lighten">{intl.formatMessage({ id: "about.desc2" })}</p>
            <p className="Text__H3 Text__Lighten">{intl.formatMessage({ id: "about.desc3" })}</p>
          </Col>
        </Row>

        {/* Skills */}
        <Row className="About__Content">
          <Col xs={{ span: 12 }} md={{ span: 3 }} className="Text__H2 Text__Regular Text__Accent Text__Darken ">
            <span className="About__Number Text__Bold">01</span> {intl.formatMessage({ id: "skills.title" })}
          </Col>

          {skills.map((x, idx) => (
            <Col
              xs={{ span: 12, offset: 0 }}
              md={{ span: 9, offset: idx === 0 ? 0 : 3 }}
              key={x.name}
              style={{ marginBottom: "2em" }}
            >
              <Row>
                <Col xs={12} md={3} className="Text__H3 Text__Regular Text__Lighten">
                  {x.name}
                </Col>
                <Col xs={12} md={{ span: 9 }}>
                  <Row>
                    {x.content.map((y) => (
                      <Col key={y.name} xs={idx === 0 ? 12 : 6} style={{ marginBottom: "1em" }}>
                        <strong className="Text__Black Text__Regular Text__H3">
                          {intl.formatMessage({ id: "skills." + y.name })}
                        </strong>
                        <div>
                          {y.content.map((z) => (
                            <div key={z} className="Text__Light Text__H4">
                              {z}
                            </div>
                          ))}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>

        {/* Experiences */}
        <Row className="Text__Darken About__Content">
          <Col xs={{ span: 12 }} md={{ span: 3 }} className="Text__H2 Text__Regular Text__Accent">
            <span className="About__Number Text__Bold">02</span> {intl.formatMessage({ id: "skills.experiences" })}
          </Col>
          {experiences.map((x, idx) => (
            <Col
              xs={{ span: 12, offset: 0 }}
              md={{ span: 9, offset: idx === 0 ? 0 : 3 }}
              key={x.title[locale]}
              style={{ marginBottom: "1em" }}
            >
              <Row>
                <Col xs={{ span: 12 }} md={{ span: 3 }} className="Text__H4 Text__Light Text__Lighten About__SubTitle">
                  {x.date}
                </Col>
                <Col xs={12} md={9}>
                  <Row>
                    <Col xs={12} className="Text__Black Text__Regular Text__H3">
                      {x.title[locale]} @{" "}
                      <a
                        className="Text__Underline"
                        href={x.company.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        {x.company.name}
                      </a>
                    </Col>
                    <Col
                      xs={12}
                      className="Text__Lighten Text__Light Text__H4"
                      dangerouslySetInnerHTML={{
                        __html: x.description?.[locale]
                      }}
                      style={{
                        paddingTop: "5px"
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>

        {/* Studies */}
        <Row className="Text__Darken About__Content">
          <Col xs={{ span: 12 }} md={{ span: 3 }} className="Text__H2 Text__Regular Text__Accent">
            <span className="About__Number Text__Bold">03</span> {intl.formatMessage({ id: "skills.studies" })}
          </Col>
          {studies.map((x, idx) => (
            <Col
              xs={{ span: 12, offset: 0 }}
              md={{ span: 9, offset: idx === 0 ? 0 : 3 }}
              key={x.title[locale]}
              style={{ marginBottom: "1em" }}
            >
              <Row className="Container__Row">
                <Col xs={{ span: 12, offset: 0 }} md={3} className="Text__H4 Text__Light Text__Lighten About__SubTitle">
                  {x.date}
                </Col>
                <Col xs={12} md={9}>
                  <Row>
                    <Col xs={12} className="Text__Black Text__Regular Text__H3">
                      {x.title[locale]}
                    </Col>
                    <Col
                      xs={12}
                      className="Text__Lighten Text__Light Text__H4"
                      style={{
                        paddingTop: "5px"
                      }}
                    >
                      {x.university?.[locale]}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
        <Footer />
      </div>
    </Layout>
  )
}

export default About
